<template>
  <div>
    <!-- EMPTY STATE -->
    <div v-if="!apiOauthAppRegistrations.length" class="empty-state-container">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="empty-state-img">
          <img class="h-100" src="~assets/empty-states/empty-state.svg" />
        </div>
        <div class="empty-state-txt">
          <div class="empty-state-title mb-2">
            {{ $t('settings.integration.mayday-api.empty-state-title') }}
          </div>
          <span class="empty-state-subtitle mb-3">{{
            $t('settings.integration.mayday-api.empty-state-description')
          }}</span>
        </div>
        <el-button type="primary" @click="addCredentials">
          {{ $t('settings.integration.mayday-api.add-button') }}</el-button
        >
      </div>
    </div>

    <div v-else>
      <MaydayApiDropdown
        v-for="(apiOauthAppRegistration, index) in apiOauthAppRegistrations"
        :key="apiOauthAppRegistration.id"
        :api-oauth-app-registration="apiOauthAppRegistration"
        :force-open="
          apiOauthAppRegistration.isNewlyCreated ||
          index === apiOauthAppRegistrations.length - 1
        "
        @update="debouncedUpdated($event, index)"
      />
      <el-button class="mt-4" type="primary" @click="addCredentials">
        {{ $t('settings.integration.mayday-api.add-button') }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import debounce from 'lodash.debounce';

import MaydayApiDropdown from './MaydayApi/MaydayApiDropdown.vue';

export default {
  name: 'IntegrationSettingsMaydayApi',
  components: {
    MaydayApiDropdown,
  },
  data() {
    return {
      apiOauthAppRegistrations: [],
      debouncedUpdated: debounce(function (payload, index) {
        this.handleUpdate(payload, index);
      }, 500),
    };
  },
  async mounted() {
    this.apiOauthAppRegistrations = await this.getApiOAuthAppRegistrations();
  },
  methods: {
    async addCredentials() {
      const newCredentials = await this.createApiOAuthAppRegistration();
      this.apiOauthAppRegistrations.push({
        ...newCredentials,
        isNewlyCreated: true,
      });
    },
    async handleUpdate(payload, index) {
      const updated = await this.updateApiOAuthAppRegistration(payload);

      const previous = this.apiOauthAppRegistrations[index];

      if (previous.isNewlyCreated) {
        updated.isNewlyCreated = true;
        updated.clientId = previous.clientId;
        updated.clientSecret = previous.clientSecret;
      }

      this.apiOauthAppRegistrations.splice(index, 1, updated);
    },
    ...mapActions('adminModule', [
      'getApiOAuthAppRegistrations',
      'createApiOAuthAppRegistration',
      'updateApiOAuthAppRegistration',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.empty-state-container {
  margin-top: 120px;
}
.empty-state-img {
  height: 100px;
}

.empty-state-txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px 30px;
}

.empty-state-subtitle {
  font-size: 14px;
  color: $grey-6-mayday;
}
</style>
